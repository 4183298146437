import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  authorizeUri = (options) => {
    const excludedParams = ['scope', 'host'];
    const params = new URLSearchParams();

    Object.keys(options).forEach((key) => {
      if (!excludedParams.includes(key)) {
        let value = options[key];
        if (Array.isArray(value)) value = value.join('+');
        params.append(key, value);
      }
    });

    const scope = options.scope ? options.scope.join('%20') : '';
    return `${options.host}?${params.toString()}&scope=${scope}`;
  };

  authorize = (options) => {
    window.location.assign(this.authorizeUri(options));
  };

  facebookLogin = (_event) => {
    console.log('facebook login clicked!');
    event.preventDefault();

    this.authorize({
      host: 'https://www.facebook.com/v18.0/dialog/oauth',
      client_id: '296639323344705',
      client_secret: 'fc6c30c7dccc7675756bb6746a6b1d93',
      display: 'popup',
      redirect_uri: `${window.location.protocol}//${window.location.host}/auth/facebook/callback`,
      response_type: 'token',
      scope: ['email', 'openid', 'public_profile'],
    });
  };

  googleLogin = (_event) => {
    console.log('google login clicked!');
    event.preventDefault();

    this.authorize({
      host: 'https://accounts.google.com/o/oauth2/v2/auth',
      client_id: '41984172619-ofno5bv1te0tbitjkmrfnqq46f3ej3m1.apps.googleusercontent.com',
      redirect_uri: `${window.location.protocol}//${window.location.host}/auth/google/callback`,
      response_type: 'token',
      scope: ['email', 'openid', 'profile'],
    });
  };

  appleLogin = (event) => {
    event.preventDefault();

    this.authorize({
      host: 'https://appleid.apple.com/auth/authorize',
      client_id: 'com.kidium.client',
      redirect_uri: `${window.location.protocol}//${window.location.host}/auth/apple/login`,
      response_type: 'code id_token',
      scope: ['email'],
      response_mode: 'form_post',
      state: Date.now().toString()
    });
  };
}
