import { Controller } from '@hotwired/stimulus'
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = ["sendCode", "verifyCode", "verificationCodeSection", "userName", 
                    "userEmail", "userPhone", "codeInput", "resendCode"];
  static values = {
    registerUrl: String,
    verifyUrl: String,
    uuid: String
  };

  t = I18n.users.registrations.validation;

  async registerContact(event) {
    event.preventDefault();
    const firstName = this.userNameTarget.value;
    const email = this.userEmailTarget.value;
    const phone = this.userPhoneTarget.value;
    const emptyInput = this.t.input_empty;
    
    let isValid = true;

    if (!firstName) {
      this.setError(this.userNameTarget, emptyInput);
      isValid = false;
    } else {
      this.clearError(this.userNameTarget);
    }

    if (!email) {
      this.setError(this.userEmailTarget, emptyInput);
      isValid = false;
    } else {
      this.clearError(this.userEmailTarget);
    }

    const phonePattern = /^\+\d{7,14}$/;
    if (phone && !phonePattern.test(phone)) {
      this.setError(this.userPhoneTarget, this.t.phone.invalid_number);
      isValid = false;
    } else {
      this.clearError(this.userPhoneTarget);
    }

    if (!isValid) {
      return;
    }

    try {
      const response = await post(this.registerUrlValue, {
        body: JSON.stringify({ user: { first_name: firstName, email, phone } }),
        contentType: "application/json",
        responseKind: "json"
      });

      if (response.ok) {
        alert(this.t.success_sent);
        this.verificationCodeSectionTarget.classList.remove("d-none");
        this.sendCodeTarget.classList.add("d-none");
      } else {
        const errorData = await response.json;
        if (errorData && errorData.errors) {
          this.handleErrors(errorData.errors);
        } else {
          alert(this.t.unknown_error);
        }
      }
    } catch (error) {
      console.error("Error registering contact:", error);
      alert(this.t.unknown_error);
    }
  }

  async verifyUser(event) {
    event.preventDefault();
    const uuid = this.uuidValue;
    const email = this.userEmailTarget.value;
    const phone = this.userPhoneTarget.value;
    const code = this.codeInputTargets.map((input) => input.value).join("");

    if (code.length < 6) {
      alert(this.t.code.invalid);
      return;
    }

    try {
      const response = await post(this.verifyUrlValue, {
        body: JSON.stringify({ email, phone, code, uuid }),
        contentType: "application/json",
        responseKind: "json"
      });

      if (response.ok) {
        console.log("User verified successfully");
        window.location.reload();
        alert(this.t.code.success);
      } else {
        const errorData = await response.json;
        if (errorData && errorData.errors) {
          this.handleVerificationErrors(errorData.errors);
        } else {
          alert(this.t.unknown_error);
        }
      }
    } catch (error) {
      console.error("Error verifying user:", error);
      alert(this.t.unknown_error);
    }
  }

  handleErrors(errors) {
    Object.keys(errors).forEach(attribute => {
      const error = errors[attribute][0];
      if (attribute === "first_name") {
        this.setError(this.userNameTarget, this.t.name.invalid );
      } else if (attribute === "email") {
        if (error.error === "already_sent") {
          alert(this.t.code.already_sent);
        } else {
          this.setError(this.userEmailTarget, this.t.email.invalid );
        }
      } else if (attribute === "phone") {
        if (error.error === "taken") {
          this.setError(this.userPhoneTarget, this.t.phone.already_taken);
        } else {
          this.setError(this.userPhoneTarget, this.t.phone.incorrect );
        }
      } else {
        alert(this.t.unknown_error);
      }
    });
  }

  handleVerificationErrors(errors) {
    Object.keys(errors).forEach(attribute => {
      const error = errors[attribute][0];
      if (error.error === 'expired') {
        alert(this.t.code.expired);
      } else if (error.error === 'missed') {
        alert(this.t.code.missing);
      } else if (error.error === 'invalid') {
        alert(this.t.code.incorrect);
      } else {
        alert(this.t.unknown_error);
      }
    });
  }

  setError(element, message) {
    element.classList.add("is-invalid");
    element.classList.add("border", "border-danger");
    let errorElement = element.nextElementSibling;
    if (!errorElement || !errorElement.classList.contains("invalid-feedback")) {
      errorElement = document.createElement("div");
      errorElement.classList.add("invalid-feedback");
      element.parentNode.appendChild(errorElement);
    }
    errorElement.textContent = message;
  }

  clearError(element) {
    element.classList.remove("is-invalid");
    element.classList.remove("border", "border-danger");
    const errorElement = element.nextElementSibling;
    if (errorElement && errorElement.classList.contains("invalid-feedback")) {
      errorElement.remove();
    }
  }

  handleInput(event) {
    const value = event.target.value.replace(/\D/g, "").charAt(0);
    event.target.value = value || "";

    const index = this.codeInputTargets.indexOf(event.target);
    if (value && index < this.codeInputTargets.length - 1) {
      this.codeInputTargets[index + 1].focus();
    }
  }

  handleBackspace(event) {
    if (event.key === "Backspace" && !event.target.value) {
      const index = this.codeInputTargets.indexOf(event.target);
      if (index > 0) {
        this.codeInputTargets[index - 1].focus();
      }
    }
    if (event.key === "Escape") {
      this.clearCodeInputs();
    }
  }

  handlePaste(event) {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("text");
    if (/^\d{6}$/.test(clipboardData)) {
      this.clearCodeInputs();
      clipboardData.split("").forEach((char, i) => {
        this.codeInputTargets[i].value = char;
      });
      this.codeInputTargets[this.codeInputTargets.length - 1].focus();
    } else {
      alert(this.t.code.unformat);
    }
  }

  clearCodeInputs() {
    this.codeInputTargets.forEach((input) => (input.value = ""));
    this.codeInputTargets[0].focus();
  }
}