// app/javascript/controllers/phone_input_controller.js
import { Controller } from '@hotwired/stimulus'
import intlTelInput from 'intl-tel-input'

export default class extends Controller {
  static targets = ['phoneField', 'errorMsg']

  connect() {
    this.errorMap = [
      I18n.users.registrations.validation.phone.invalid_number,
      I18n.users.registrations.validation.phone.invalid_country_code,
      I18n.users.registrations.validation.phone.too_short,
      I18n.users.registrations.validation.phone.too_long,
      I18n.users.registrations.validation.phone.invalid_number
    ]

    this.iti = intlTelInput(this.phoneFieldTarget, {
      initialCountry: 'auto',
      nationalMode: false,
      strictMode: true,
      preventInvalidDialCodes: true,
      autoPlaceholder: 'aggressive',
      formatOnDisplay: true,
      separateDialCode: false,
      loadUtils: () => import("intl-tel-input/build/js/utils"),
      geoIpLookup: callback => {
        fetch('https://ipapi.co/json')
          .then(res => res.json())
          .then(data => callback(data.country_code))
          .catch(() => callback('ua'))
      }
    })
  }

  resetError() {
    this.phoneFieldTarget.classList.remove('error')
    this.errorMsgTarget.textContent = ''
    this.errorMsgTarget.classList.add('d-none')
  }

  input() {
    this.resetError()
  }

  blur() {
    this.resetError()

    if (this.phoneFieldTarget.value.trim()) {
      if (!this.iti.isValidNumber()) {
        const errorCode = this.iti.getValidationError()
        const errorText =
          this.errorMap[errorCode] || 'Invalid phone number'

        this.errorMsgTarget.textContent = errorText
        this.errorMsgTarget.classList.remove('d-none')
        this.phoneFieldTarget.classList.add('error')
      }
    }

    const e164Number = this.iti.getNumber()
    this.phoneFieldTarget.value = e164Number
  }
}
