import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["button"]
  static values = {
    uuid: String,
    authForm: String,
    needToSaveData: { type: Boolean, default: false }
  }

  connect() {
    this.originalUrl = window.location.href
  }

  saveFormData() {
    const form = this.element.closest("form");
    const excludedIds = ['authenticity_token', 'first_name', 'email', 'phone'];
    if (form) {
      const formData = new FormData(form);
      const data = {};

      formData.forEach((value, key) => {
        if (value && !excludedIds.includes(key)) {
          const input = form.querySelector(`[name="${key}"]`);

          if (input && input.tagName === 'SELECT') {
            const selectedOption = input.options[input.selectedIndex];
            data[key] = { value: selectedOption.value, text: selectedOption.text };
          } else {
            data[key] = value;
          }
        }
      });

      if (Object.keys(data).length > 0) {
        localStorage.setItem(`${this.formIdentifier}-${this.uuidValue}`, JSON.stringify(data));
      }
    }
  }

  async saveState(_event) {
    if (this.needToSaveDataValue) {
      try {
        this.saveFormData()
      } catch (error) {
        console.error("Произошла ошибка при сохранении данных формы", error)
      }
    }
  }
  async saveStateAndCall(_event) {
    await this.saveState()
    if (this.authForm) this.authForm.submit()
  }

  get authForm() {
    if (!this._authFormElement) {
      const identier = this.authFormValue
      this._authFormElement = document.querySelector(`#${identier}`)
    }

    return this._authFormElement
  }

  get formIdentifier() {
    return this.element.closest("form").id
  }
}
