import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["fieldsContainer", "onlineCheckbox", "countrySelect", "citySelect"]

  connect() {
    this.toggleFields()
  }

  toggleFields() {
    if (this.onlineCheckboxTarget.checked) {
      this.fieldsContainerTarget.classList.add('d-none')
    } else {
      this.fieldsContainerTarget.classList.remove('d-none')
    }
  }

  onCountryChange(event) {
    const countryId = event.target.value
  }
}
