import { Controller } from '@hotwired/stimulus'
import fadeIn from "controllers/_lib/animate/fadeIn.js"
import fadeOut from "controllers/_lib/animate/fadeOut.js"

export default class extends Controller {
  static targets = [ 'tabMultiItems' ]

  connect() {
    if (this.hasTabMultiItemsTarget && this.tabMultiItemsTarget.classList.contains('d-none')) this.activateMultiTabs()
  }

  activateMultiTabs() {
    if (!this.hasTabMultiItemsTarget) return
    
    const isHidden = this.tabMultiItemsTarget.classList.contains('d-none')
    if (!isHidden) return
    
    fadeIn(this.tabMultiItemsTarget, 500, 'd-none') 
  }

  deactivateMultiTabs() {
    if (!this.hasTabMultiItemsTarget) return

    const isHidden = this.tabMultiItemsTarget.classList.contains('d-none')
    if (isHidden) return
    
    fadeOut(this.tabMultiItemsTarget, 500, 'd-none') 
  }
}
